<template>
  <div class="bar_info_box" :style="varStyle">
    <div class="text_box">{{ nub + unit }}</div>
    <div class="bar"></div>
  </div>
</template>

<script>
  export default {
    props: {
      height: {
        type: Number,
        default: 100,
      },
      nub: {
        type: Number || String,
        default: 0,
      },
      unit: {
        type: String,
        default: "°",
      },
      maxVal: {
        type: Number,
        default: 50,
      },
    },
    data() {
      return {};
    },
    computed: {
      varStyle() {
        const barHeight = ((this.height - 20) * Number(this.nub)) / this.maxVal;
        return {
          "--warpHeight": this.height + "px",
          "--barHeight": barHeight + "px",
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .bar_info_box {
    height: var(--warpHeight);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    .text_box {
      height: 20px;
      font-size: 12px;
    }
    .bar {
      height: var(--barHeight);
      width: 3px;
      background: var(--el-color-primary);
      border-radius: 1.5px;
    }
  }
</style>
