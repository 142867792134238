<template>
   <paramBox :dataList='dataList' @onclose='colseClick' :width='boxWidth' :style="boxHeight">
    <div class="content_box">
        <div>
            <el-carousel 
               class="wh100" 
               indicator-position= 'none' 
               height='100%' 
               :autoplay= 'false'
               @change='carouselChange'
               arrow="always">
                <el-carousel-item v-for="(item,i) in paramList" :key="i" >
                   <el-image :src="imgSrc(item)" class="wh100">

                        <div slot="error" class="image-slot wh100">
                                <i class="el-icon-picture-outline"></i>
                         </div>
                   </el-image>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div v-if="isAnalysis">
            <template v-if="imgAnsInfo && imgAnsInfo.anss.length">
                <ul class="insect_info_box">
                    <li v-for="(item,i) of imgAnsInfo.anss" :key="i" :class="isBg(i)">
                        <span>{{ item.cdnLibName }}</span>
                        <span>{{ `${item.num}只`}}</span>
                    </li>
                </ul>
            </template>
            <template v-else>
                <el-empty description="暂无虫情" :image-size="70"></el-empty>
            </template>
            
        </div>
    </div>
   </paramBox>
</template>

<script>
    import paramBox from '@/components/localComponent/paramBox.vue'
    export default {
        name: 'insectParam',
        components:{
            paramBox
        },
        props:{
            dataList:{
                type: Object,
            }
        },
        data(){
           return {
               insectList: [
                   {
                       name: '二号机',
                       nub: '5'
                   },
                   {
                       name: '二号机',
                       nub: '5'
                   },
                   {
                       name: '二号机',
                       nub: '5'
                   },
                   {
                       name: '二号机',
                       nub: '5'
                   },
                   {
                       name: '二号机',
                       nub: '5'
                   },
               ],
               imgAnsInfo: null
           }
        },
        computed:{
            paramList(){
               let info = this.dataList
        
               if(info && info.data){
                   let paramInfo = JSON.parse(info.data)
        
                   if(info.isAnalysis && paramInfo.length){
                       this.imgAnsInfo = paramInfo[0]
                   }
                   return paramInfo
                  
               }
               return []
            },
            isAnalysis(){
                let info = this.dataList
                return info && info.isAnalysis ? true : false
            },
            boxWidth(){
              return  this.isAnalysis ? '400px' : '280px'
            },
            boxHeight(){
               let height = this.isAnalysis ? '180px' : '200px'
               return {
                   '--height':height
               }
            },
            imgSrc(){
                
              return val => {
                return val ?.imgUrl || val
              }
            },
            isBg(){
               return i => {
                   return i%2 !== 0 ? 'base-bg-opacity2': ''
               }
            },
        },
        methods:{
            colseClick(){
               this.$emit('onclose')
            },
            carouselChange(i){
              
               if(this.isAnalysis){
                  this.imgAnsInfo = this.paramList[i]
               }
               
            }
        }
    }
</script>

<style lang="scss" scoped>
.content_box{
   width: 100%;
   height: var(--height);
   display: flex;
   padding: 15px 5px 5px 5px;
   box-sizing: border-box;
   & > div:first-child{
       flex: 1;
       height: 100%;
       
   }
   .el-image ::v-deep .image-slot{
           font-size: 28px;
           display: flex;
           align-items: center;
           justify-content: center;
           border: 1px solid #999;
           box-sizing: border-box;
    }
   .el-carousel ::v-deep .el-carousel__arrow{
       background-color: transparent;
       color: var(--el-color-primary);
       font-size: 28px;
   }
   .el-empty{
       padding: 15px 0;
   }
   & > div:last-child{
       width: 165px;
       height: 100%;
       margin-left: 15px;
       overflow: auto;
       .insect_info_box{
          padding-right: 5px;
          box-sizing: border-box;
           & > li {
               height: 34px;
               border-radius: 5px;
               display: flex;
               align-items: center;
               justify-content: space-between;
               padding: 0 18px;
               box-sizing: border-box;

           }
       }
   }
}
</style>