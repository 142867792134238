<template>
    <div class="mapTabs_box">
      
     
      
        <el-input
            class="csutom_input"
            placeholder="请输入区域名称"
            clearable
            @clear="toClear"
            size="mini"
            v-model="agrVal">
            <i slot="suffix" @click="toSearch" class="el-input__icon el-icon-search"></i>
        </el-input>
        <div class="list scrollbarMiniGary">
            <div :class="['baseBut',activeArea == item.id ? 'active_area' : '']" @click="areaChange(item)" v-for="item of dataList" :key="item.id">{{ item.name }}</div>
        </div>
            
      
        
    </div>
</template>

<script>
   
    import bus from '@/libs/eventBus'
    import debounce from '@/libs/debounce'
    export default {
        props:{
            areaInfoList:{
                type: Array,
                default: () => []
            },
            value:{
                type: String,
                default: ''
            }
        },
        watch:{
            value(val){
                this.activeArea = val
            },
            areaInfoList(val){
                this.dataList = val
            }
        },
        data(){
            return{
               
              
                dataList: [],
                activeArea: '',
                activeAgr: '',
                agrVal: '',
                copyList: []
            }
        },
        created(){
            // bus.$on('changeMenuActive',id => {
            //     if(id == this.activeAgr) this.activeAgr = ''
            // })
        },
        computed:{
             filterData(){
                 if(!this.agrVal) return this.areaInfoList
                 return this.areaInfoList.filter(item => item.name.indexOf(this.agrVal) != -1 )
             }
        },
        methods:{
            toClear(){
                this.dataList = this.areaInfoList
            },
            
            areaChange(item){
                this.activeArea = item.id
                this.$emit('input',item.id)
                if(item.geo || item.points.length){
                    this.$emit('changeArea',item)
                }else{
                    this.$msg.info("当前区域没有地理信息");
                }
                    
            },
            toSearch(){
              
                debounce(() => {
               
                    this.dataList = this.filterData
                },300)
            },
            
           
            
        }
    }
</script>

<style lang="scss" scoped>
.mapTabs_box{
    width: 185px;
   
   .csutom_input{
      margin-bottom: 10px;
      width: 180px;
   }
   .list{
    height: 300px;
    overflow-y: auto;
    .baseBut {
      width: 180px;
      color: #fff;
      font-size: 14px;
      height: 28px;
      line-height: 28px;
      border-radius: 114px;
      background: rgba(#002B46,.5);
      margin-bottom: 10px;
      text-align: center;
      padding: 0 10px;
      cursor: pointer;
      overflow: hidden;
      word-break: break-all;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      box-sizing: border-box;
    }
    .active_area{
        background: rgba(#04E6B4,0.5);
        color: #fff;
    }
   }
   .csutom_input{
        & > ::v-deep .el-input__inner{
            height: 28px;
            line-height: 28px;
            background-color: rgba(#04E6B4,0.5);
            border: none;
            color: #fff;
        }
        ::v-deep .el-input__inner::placeholder {
            color: #fff;
            
        }
        ::v-deep .el-input__suffix{
            color: #fff;
        }
        ::v-deep .el-input__clear{
            color: #fff;
        }
   }
}
</style>